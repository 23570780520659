import axiosApi from '@/axios'
import router from '@/router'
import Swal from 'sweetalert2'
import store from '@/store'

export default {

    props:['id'],

    data() {
        return {
            errors:{},
            name: '',
            email: '',
            password: '',
            isEdit: false,
            title: '',
            admin: false,
            active: true,
            isAccount: false,
            changeAdmin: false
        }
    },

    watch:{

    },

    computed:{
        idActive(){
            if (this.isAccount){
                return this.getUserID
            }else{
                return this.id
            }
        }
    },

    created() {
        if (this.$route.name == 'account'){
            this.isEdit = true
            this.title = 'Minha Conta'
            this.isAccount = true
        }else{
            if (this.id){
                this.isEdit = true
            }else{
                this.isEdit = false
                this.title = 'Novo Usuário'
            }
        }

    },

    mounted() {
        if (this.isEdit){
            this.load()
        }
    },

    methods: {
        load(){
            axiosApi.get(`/whatsapp-interacao/user/${this.idActive}`, { clearCacheEntry: true })
                .then( (response) =>{
                    this.name = response.data.usuario.nome
                    this.email = response.data.usuario.email
                    this.admin = response.data.usuario.admin
                    this.active = response.data.usuario.ativo
                    this.changeAdmin = response.data.usuario.admin
                    this.title = this.isAccount ? 'Minha Conta': this.name
            })
                .catch( (error) => {
                    if (error.response.status != 401){
                        Swal.fire(
                            'Usuário',
                            'O usuário é invalido ou inexistente.',
                            'error'
                        ).then(() => {
                            router.push({name: 'listuser'})
                        })  
                    }
            })
        },

        resetPassword(){
            var data = {'senha': this.password}
            this.errors = {}
            axiosApi.put(`/whatsapp-interacao/user/edit/${this.idActive}`, data)
                .then( () =>{
                    Swal.fire(
                        'Senha',
                        'A senha foi alterada com sucesso.',
                        'success'
                    ).then(() => {
                        this.password = ''
                    })
            })
                .catch( (error) => {
                    if ('campo_erro' in error.response.data){
                        this.errors = error.response.data.campo_erro
                    }
            })
        },

        save(){
            var data = null

            if (this.isEdit){
                data = {'email': this.email, 'nome': this.name, 'admin': this.admin, 'ativo': this.active}
                axiosApi.put(`/whatsapp-interacao/user/edit/${this.idActive}`, data)
                .then( () =>{
                    if (this.changeAdmin != this.admin && this.getUserID == this.idActive || (!this.active && this.admin)){
                        Swal.fire(
                            'Alterado',
                            'Os dados foram alterados com sucesso. Como houve alteração de processo administrativo, deverá logar novamente.',
                            'success'
                        ).then(() => {
                            store.commit('logout')
                            router.push('/login')
                        })
                    }else{
                        Swal.fire(
                            'Alterado',
                            'Os dados foram alterados com sucesso.',
                            'success'
                        ).then(() => {
                            if (this.isAdmin){
                                router.push({name: 'listuser'})
                            }
                        })
                    }
                })
                .catch( (error) => {
                    if ('campo_erro' in error.response.data){
                        this.errors = error.response.data.campo_erro
                    }
                })
            }else{
                data = {'email': this.email, 'senha': this.password, 'nome': this.name, 'admin': this.admin, 'ativo': true}
                axiosApi.post('/whatsapp-interacao/user/add', data)
                    .then( () =>{
                        Swal.fire(
                            'Cadastrado',
                            'O usuário foi cadastrado com sucesso.',
                            'success'
                        ).then(() => {
                            if (this.isAdmin){
                                router.push({name: 'listuser'})
                            }
                        })
                })
                    .catch( (error) => {
                        if ('campo_erro' in error.response.data){
                            this.errors = error.response.data.campo_erro
                        }
                })
            }

        }
   
    }
}