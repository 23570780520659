<script>
import { Line } from 'vue-chartjs';
import EventBus from '@/event/event-bus'
export default {
    extends: Line,

    props:{
        id: Number
    },

    data(){
        return{
            options:{
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales:{
                    xAxes: [{
                        display: false
                    }]
                }
            }
        }
    },

    mounted() {
        EventBus.$on('onChangeLineChart', (id, data) => {
            if (this.id === id){
                setTimeout(() => {
                    this.renderChart(data, this.options)
                }, 250);
            }
        })

    }
};
</script>