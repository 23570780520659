import Vue from 'vue'
import Vuex from 'vuex'
import jwt_decode from 'jwt-decode'

const getAdmin = () => {
    if (localStorage.getItem('token')){
        var token = jwt_decode(localStorage.getItem('token'))
        if (token.id){
            return token.admin
        }else{
            return false
        }
    }else{
        return false
    }
};

const getUserID = () => {
    if (localStorage.getItem('token')){
        var token = jwt_decode(localStorage.getItem('token'))
        if (token.id){
            return token.id
        }else{
            return 0
        }
    }else{
        return 0
    }
};

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLogin: localStorage.getItem('token') != null,
        loading: false,
        token: localStorage.getItem('token'),
        phoneDefault: '',
        searchCompaign: false,
        isAdmin: getAdmin(),
        idUser: getUserID(),
        isCache: true,
        loadingManual: false 
    },

    mutations: {
        setLogin(state, value){
            localStorage.setItem('token', value)
            state.isLogin = true
            state.token = value
            state.isAdmin = getAdmin()
            state.idUser = getUserID()
        },

        setLoading(state, value){
            state.loading = value
        },

        setPhoneDefault(state, value){
            state.phoneDefault = value
        },

        setSearchCompaign(state, value){
            state.searchCompaign = value
        },

        setClearCache(state, value){
            state.isCache = !value
        },

        setLoadingManual(state, value){
            state.loadingManual = value
            if (!value){
                state.loading = false
            }
        },

        logout(state){
            state.isLogin = false
            localStorage.removeItem('token')
        },
    },

    getters: {
        isLogin(state){
            return state.isLogin
        },

        phoneDefault(state){
            return state.phoneDefault
        },

        isSearchCompaign(state){
            return state.searchCompaign
        },

        isCache(state){
            return state.isCache
        },

        isLoading(state){
            return state.loading
        },

        isLoadingManual(state){
            return state.loadingManual
        },

        getToken(state){
            return state.token
        },

        isAdmin(state){
            return state.isAdmin
        },

        getUserID(state){
            return state.idUser
        }
    },

    actions: {
    }
})
