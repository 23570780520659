<script>
import EventBus from '@/event/event-bus'
import { Pie } from "vue-chartjs";

export default {
    extends: Pie,

    data(){
        return{
            options: {
                responsive: true,
                maintainAspectRatio: false
            },
        }
    },

    props:{
        id: Number
    },

    mounted() {
        EventBus.$on('onChangePierChart', (id, data) => {
            if (this.id === id){
                setTimeout(() => {
                    this.renderChart(data, this.options)
                }, 250);
            }
        })
    }
};
</script>



    
  



         