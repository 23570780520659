import TagKey from '@/components/tagkey/TagKey.vue'
import axiosApi from '@/axios';
import {mapGetters, mapMutations } from 'vuex'
import router from '@/router'
import Swal from 'sweetalert2'

export default {
    components: {
        TagKey
    },

    props:['phone', 'id'],

    data() {
        return {
            isEdit: false,
            tags: [],
            errors:{},
            nameCompaign: '',
            urlWebHook: '',
            active: true,
            token: '',
            numberUnique: false,
            phones:[],
            selectPhone: '',
            title: ''
        }
    },

    watch:{

    },

    computed:{
        ...mapGetters(['phoneDefault']),
    },

    created() {
        if (this.id && this.phone){
            this.isEdit = true
        }else{
            this.isEdit = false
        }

    },

    mounted() {
        axiosApi.get('/whatsapp-interaction/campaign/phones')
            .then( (response) =>{
                this.phones = response.data.telefones
                this.loadCampaign()
            if (this.phoneDefault){
                
                if (this.$route.params.new){
                    this.selectPhone = ''
                }else{
                    this.selectPhone = this.phoneDefault
                }

                this.setPhoneDefault('')
            }
        })
    },

    methods: {
        ...mapMutations(['setPhoneDefault', 'setClearCache']),
        toListCampaing(){
            this.setPhoneDefault(this.selectPhone)
            router.push({name: 'listcampaigns'})
        },

        cancel(){
            this.toListCampaing()
        },

        loadCampaign(){
            if (this.isEdit){
                axiosApi.get(`/whatsapp-interaction/campaign/${this.phone}/${this.id}`, { clearCacheEntry: true })
                .then( (response) =>{
                    var campaign = response.data.campanha
                    this.tags = campaign.palavras_chaves
                    this.token = campaign.token
                    this.urlWebHook = campaign.url_webhook_post
                    this.nameCompaign = campaign.nome_promocao
                    this.selectPhone = this.phone
                    this.active = !campaign.desativado
                    this.numberUnique = campaign.numero_unico
                    this.title = this.nameCompaign
                })
                .catch( () => {
                    Swal.fire(
                        'Erro',
                        'A campanha é invalida ou inexistente',
                        'error'
                    ).then(()=>{
                        router.push({name: 'dashboard'})
                    })
                })
            }else{
                this.title = 'Nova Campanha'
            }
        },

        save(){
            var data = {
                nome_promocao: this.nameCompaign,
                url_webhook_post: this.urlWebHook,
                desativado: !this.active,
                token: this.token,
                palavras_chaves: this.tags,
                numero_unico: this.numberUnique
            }

            if (!this.isEdit){
                if (this.selectPhone){
                    axiosApi.post(`/whatsapp-interaction/campaign/${this.selectPhone}/add`, data)
                        .then( () =>{
                        Swal.fire(
                            'Incluido!',
                            'A campanha foi incluída com sucesso!',
                            'success'
                        ).then(() => {
                            this.setClearCache(true)
                            this.toListCampaing()
                        })
                    })
                        .catch( (error) => {
                            if ('campo_erro' in error.response.data){
                                this.errors = error.response.data.campo_erro
                            }
                    })
                }
            }else{
                axiosApi.put(`/whatsapp-interaction/campaign/${this.phone}/edit/${this.id}`, data)
                    .then( () =>{
                        Swal.fire(
                            'Alterado!',
                            'A campanha foi alterada com sucesso!',
                            'success'
                        ).then(() => {
                            this.setClearCache(true)
                            this.toListCampaing()
                        })
                })
                    .catch( (error) => {
                        if ('campo_erro' in error.response.data){
                            this.errors = error.response.data.campo_erro
                        }
                })
            }
        }
    }
}