import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from '@/router'
import store from '@/store'
import { setup } from 'axios-cache-adapter'

Vue.use(VueAxios, axios)

const axiosApi = setup({
    baseURL: process.env.VUE_APP_BASE_URL_API,
    cache: {
        invalidate: async (config, request) => {
            if (request.clearCacheEntry) {
                await config.store.removeItem(config.uuid)
            }
        }
    }
})

axiosApi.interceptors.request.use(function (request){
    request.headers.Authorization =  `RPM ${localStorage.getItem('token')}`

    store.commit('setLoading', true)

    return request
}, function (error) {
    return Promise.reject(error)
})

axiosApi.interceptors.response.use(function (response){

    if (!store.getters.isLoadingManual){
        store.commit('setLoading', false)
    }

    return response
}, function (error) {
    store.commit('setLoading', false)

    if (error.response) {
        if (error.response.status === 401) {
            store.commit('logout')
            router.push('/login')
        }
        console.clear()
    }else{
        store.commit('logout')
        router.push('/login')
    }

    return Promise.reject(error)
})
  
export default axiosApi