import BarChart from '@/components/chart/BarChart.vue'
import PieChart from '@/components/chart/PieChart.vue'
import LineChart from '@/components/chart/LineChart.vue'
import axiosApi from '@/axios'
import CardInfo from '@/components/cardinfo/CardInfo.vue'
import EventBus from '@/event/event-bus'
import router from '@/router'
import {mapMutations, mapGetters } from 'vuex'
import _ from 'lodash'

export default {
    components: {
        BarChart,
        LineChart,
        PieChart,
        CardInfo
    },
    data() {
        return {
            user: '',
            campaigns: [],
            phones: [],
            statisticCampaign: {},
            charts:[],

            loadingChart:{campaign: false, 
                          campaignActive: false, 
                          phone: false}
        }
    },

    created() {
        this.loadingChart.campaign = true
        this.loadingChart.campaignActive = true
        this.loadingChart.phones = true

        this.setLoadingManual(true)

        axiosApi.get(`/whatsapp-interacao/user/${this.getUserID}`, { clearCacheEntry: true })
            .then( (response) =>{
                this.user = response.data.usuario.nome
        })

        axiosApi.get(`/whatsapp-interaction/campaign/phones`)
            .then( (response) =>{
                var phones = response.data.telefones
                this.phones = phones
                EventBus.$emit('onCompleteCard', 4, phones.length)
                this.load(phones)
        })
    },

    computed:{
        ...mapGetters(['isCache']),
    },

    mounted() {

    },

    watch: {

    },

    methods: {
        ...mapMutations(['setPhoneDefault', 'setClearCache', 'setLoadingManual']),

        detailCampaign(phone){
            this.setPhoneDefault(phone)
            router.push({name: 'listcampaigns'})
        },

        chartCampaign(){
            let titles = []
            let dataBase = []

            let data = {
                labels: [
                ],
                datasets: [{
                    label: "Campanha",
                    backgroundColor: "#75c181",
                    data: []
                }]
            }

            _.forEach(this.charts, (chart) => {
                titles.push(chart.title)
                dataBase.push(chart.total)
            })

            data.labels = titles
            data.datasets[0].data = dataBase
            this.loadingChart.campaign = false
            EventBus.$emit('onChangeBarChart', 1, data)
        },

        chartCampaignActives(){
            let titles = []
            let dataBase = []

            let data = {
                labels: [],
                datasets: [
                    {
                        label: "Campanha",
                        data: [2, 10, 5, 9, 0, 6, 20],
                        backgroundColor: "transparent",
                        borderColor: "rgba(1, 116, 188, 0.50)",
                        pointBackgroundColor: "rgba(171, 71, 188, 1)"
                    }
                ] 
            }

            _.forEach(this.charts, (chart) => {
                if (chart.active){
                    titles.push(chart.title)
                    dataBase.push(chart.total)
                }
            })

            data.labels = titles
            data.datasets[0].data = dataBase
            this.loadingChart.campaignActive = false
            EventBus.$emit('onChangeLineChart', 1, data)
        },

        chartPhones(){
            let data = {
                labels: [],
                datasets: [{
                    backgroundColor: ["#6F69AC", "#95DAC1", "#FFEBA1", "#FD6F96", "#FFB319", "#FFE194", "#FF2442"],
                    data: [5500, 5000, 10000],
                }, ],
            }

            let total = []
            _.forEach(this.phones, (phone) => {
                let campaign = _.filter(this.charts, { 'phone': phone})

                let count = 0
                _.forEach(campaign, (item) => {
                    count += item.total
                })

                total.push(count)
            })

            data.labels = this.phones
            data.datasets[0].data = total
            this.loadingChart.phones = false
            EventBus.$emit('onChangePierChart', 1, data)
        },

        async load(phones){
            for (const phone of phones){
                let item = {}
                let request = await axiosApi.get(`/whatsapp-interaction/campaign/${phone}`, { clearCacheEntry: !this.isCache })
                item.phone = phone
                item.campaign = request.data.campanha
                item.total = item.campaign.length
                this.campaigns.push(item)
            }

            this.setClearCache(false)

            this.setLoadingManual(false)
    
            this.statisticCampaign.total = 0
            this.statisticCampaign.active = 0
            this.statisticCampaign.interactions = 0

            _.forEach(this.campaigns, (campaign) => {
                this.statisticCampaign.total += campaign.total

                for (let i = 0; i < campaign.campaign.length; i++) {
                    if (!campaign.campaign[i].desativado){
                        this.statisticCampaign.active++
                    }
                    this.statisticCampaign.interactions += campaign.campaign[i].total_interacoes

                    let listCampaign = {} 
                    listCampaign.title = campaign.campaign[i].nome_promocao
                    listCampaign.total = campaign.campaign[i].total_interacoes
                    listCampaign.phone = campaign.campaign[i].whatsapp_id
                    listCampaign.active = !campaign.campaign[i].desativado

                    this.charts.push(listCampaign)
                }
            })

            this.chartCampaign()
            this.chartCampaignActives()
            this.chartPhones()

            EventBus.$emit('onCompleteCard', 2, this.statisticCampaign.total)
            EventBus.$emit('onCompleteCard', 3, this.statisticCampaign.active)
            EventBus.$emit('onCompleteCard', 1, this.statisticCampaign.interactions.toLocaleString('pt-BR'))
        }
    }
}