<template>
    <div :class="classDiv">
        <img v-if="!imageNotFound && !load" :src="imageLogo" :class="classImg" alt="logo-promo" />
        <img v-else-if="!load" src="@/assets/img/logo-promo.png" :class="classImg" alt="logo-promo" />
        <img v-if="load" src="@/assets/img/loading.gif" :class="classImg" alt="logo-promo" />
    </div>
</template>

<script>
export default {
    data(){
        return {
            imageNotFound: false,
            load: false
        }
    },

    props: {
        source: String,
        classDiv: String,
        classImg: String
    },


    computed: {
        imageLogo(){
            var baseLink = this.getUrlBase(this.source)
            var baseFolder = '/public/admin/images/logo-promo.png'
            var image = baseLink + baseFolder
            return image
        }
    },

    created(){

    },

    mounted() {
        this.load = true
        setTimeout(() => { 
            this.checkIfImageExists(this.imageLogo, (exists) => {
                if (!exists) {
                    this.imageNotFound = true
                }
                this.load = false
            })
        }, 1500);

        setTimeout(() => { 
            if (this.load){
                this.imageNotFound = true
                this.load = false
            }
        }, 3000);
    },

    methods:{
        getUrlBase(url){
            var baseUrl = new URL(url)
            return `${baseUrl.protocol}//${baseUrl.hostname}`
        },

        checkIfImageExists(url, callback) {
            const img = new Image();

            img.src = url;
        
            if (img.complete) {
                callback(true)
            } else {
                img.onload = () => {
                    callback(true)
                }
                img.onerror = () => {
                    console.clear()
                    callback(false)
                }
            }
        }

    }
};
</script>