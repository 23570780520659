import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Notifications from '@/components/notification/Notification.vue'


export default {
    components:{
        Loading, Notifications
    },

    data() {
        return {

        }
    },

    created() {

    },

    mounted() {
        /* ===== Responsive Sidepanel ====== */
        const sidePanelToggler = document.getElementById('sidepanel-toggler');
        const sidePanel = document.getElementById('app-sidepanel');
        const sidePanelDrop = document.getElementById('sidepanel-drop');
        const sidePanelClose = document.getElementById('sidepanel-close');

        window.addEventListener('load', function () {
            responsiveSidePanel();
        });

        window.addEventListener('resize', function () {
            responsiveSidePanel();
        });


        function responsiveSidePanel() {
            let w = window.innerWidth;
            if (w >= 1200) {
                sidePanel.classList.remove('sidepanel-hidden');
                sidePanel.classList.add('sidepanel-visible');

            } else {
                sidePanel.classList.remove('sidepanel-visible');
                sidePanel.classList.add('sidepanel-hidden');
            }
        }

        sidePanelToggler.addEventListener('click', () => {
            if (sidePanel.classList.contains('sidepanel-visible')) {
                sidePanel.classList.remove('sidepanel-visible');
                sidePanel.classList.add('sidepanel-hidden');

            } else {
                sidePanel.classList.remove('sidepanel-hidden');
                sidePanel.classList.add('sidepanel-visible');
            }
        });



        sidePanelClose.addEventListener('click', (e) => {
            e.preventDefault();
            sidePanelToggler.click();
        });

        sidePanelDrop.addEventListener('click', () => {
            sidePanelToggler.click();
        });



        /* ====== Mobile search ======= */
        const searchMobileTrigger = document.querySelector('.search-mobile-trigger');
        const searchBox = document.querySelector('.app-search-box');

        searchMobileTrigger.addEventListener('click', () => {

            searchBox.classList.toggle('is-visible');

            let searchMobileTriggerIcon = document.querySelector('.search-mobile-trigger-icon');

            if (searchMobileTriggerIcon.classList.contains('fa-search')) {
                searchMobileTriggerIcon.classList.remove('fa-search');
                searchMobileTriggerIcon.classList.add('fa-times');
            } else {
                searchMobileTriggerIcon.classList.remove('fa-times');
                searchMobileTriggerIcon.classList.add('fa-search');
            }


        });


        var elementos = document.getElementsByClassName("close-menu");

        for (var i = 0; i < elementos.length; ++i) {
            elementos[i].onclick = function () {
                closeMenu()
            };
        }

        function closeMenu() {
            var w = window.innerWidth;
            if (w <= 1199) {
                document.getElementById('sidepanel-toggler').click();
            }
        }

    },

    watch: {

    },

    methods: {


    }
}