<template>
    <div class="tags-input">
        <span v-for="tag in value" :key="tag" class="tags-input-tag">
            <span>{{ tag }}</span>
            <button
                type="button"
                class="btn tags-input-remove"
                @click="removeTag(tag)"
            >
                &times;
            </button>
        </span>
        <input
            class="tags-input-text form-control"
            placeholder="Adicionar com enter"
            @keydown.enter.prevent="addTag"
            v-model="newTag"
        />
    </div>
</template>

<script>
export default {
    props: ["value"],
    data() {
        return {
            newTag: "",
        };
    },
    methods: {
        addTag() {
            if (
                this.newTag.trim().length === 0 ||
                this.value.includes(this.newTag.trim())
            ) {
                return;
            }
            this.$emit("input", [...this.value, this.newTag.trim()]);
            this.newTag = "";
        },
        removeTag(tag) {
            this.$emit(
                "input",
                this.value.filter((t) => t !== tag)
            );
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.tags-input {
    display: flex;
    flex-wrap: wrap;
}

.tags-input-tag {
    display: inline-flex;
    align-items: center;
    background-color: $theme-color-primary;
    color: #fff;
    border-radius: 0.25rem;
    padding: 8px 11px;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
}

.tags-input-remove {
    color: #fff !important;
    line-height: 18px !important;
    font-size: 18px !important;
    padding: 0 3px 0 14px !important;
}

.tags-input-text {
    flex: 1;
}
</style>