import Vue from 'vue'
import TagKey from '@/components/tagkey/TagKey.vue'
import axiosApi from '@/axios'
import router from '@/router'
import Swal from 'sweetalert2'
import {mapMutations, mapGetters } from 'vuex'
import jwt_decode from 'jwt-decode';
import EventBus from '@/event/event-bus'

import VueMask from 'v-mask'
Vue.use(VueMask)

export default {
    components: {
        TagKey
    },

    props:['phone', 'id'],

    data() {
        return {
            errors:{},
            emails: [],
            packageInteractions: '',
            totalInteractions: 0,
            totalInteractionsLog: 0,     
            percentage: 0, 
            active: false,
            title: '',
            alertItem: {},
            campaign: {},
            idUser: 0,
            logs:{},
            logsUsers:{},
            urlAPI: '',
            intervalHours: 1,
            load: true
        }
    },

    watch:{

    },

    computed:{
        ...mapGetters(['getToken']),
    },

    created() {
        this.loadCampaign()
        this.getLogs()
    },

    mounted() {
        var token = jwt_decode(this.getToken)
        if (token.id){
            this.idUser = token.id
        }
    },

    methods: {
        ...mapMutations(['setPhoneDefault', 'setClearCache']),
        onInputValue(event){
            var _this = event.target,
            num = _this.value.replace(/\./g,'')
            if(!isNaN(num)){
                num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.')
                num = num.split('').reverse().join('').replace(/^[\\.]/,'')
                _this.value = num
                var start = _this.selectionStart,
                    end = _this.selectionEnd
                _this.setSelectionRange(start, end)
                this.packageInteractions = _this.value
                this.errors.total_interacao = ''
            }else{
                this.packageInteractions = ''
                this.errors.total_interacao = 'Informe apenas valores númericos.'
            }
        },

        loadCampaign(){
            axiosApi.get(`/whatsapp-interaction/campaign/${this.phone}/${this.id}`, { clearCacheEntry: true })
                .then( (response) =>{
                    this.campaign = response.data.campanha
                    this.alertItem = response.data.campanha.alerta
                    if (this.alertItem.id){
                        this.getCampaign()
                    }else{
                        this.title = this.campaign.nome_promocao
                        this.load = false
                    }
                    this.urlAPI = `${process.env.VUE_APP_BASE_URL_API}/whatsapp/campaign/${this.campaign.whatsapp_id}/${this.campaign.id}`
                })
                .catch( () => {
                    Swal.fire(
                        'Erro',
                        'A campanha é invalida ou inexistente',
                        'error'
                    ).then(()=>{
                        router.push({name: 'listcampaigns'})
                    })
                })
        },

        getCampaign(){
            this.title = this.campaign.nome_promocao
            this.totalInteractions = this.alertItem.total_interacao
            this.totalInteractionsLog = this.alertItem.total_interacao
            this.percentage = this.alertItem.alertar_porcentagem
            this.emails = this.alertItem.alertar_emails
            this.active = this.alertItem.ativado
            this.intervalHours = this.alertItem.intervalo_horas
            this.load = false
        },

        getLogs(){
            axiosApi.get(`/whatsapp-interaction/campaign/${this.phone}/alert/logs/${this.id}`, { clearCacheEntry: true })
            .then( (response) =>{
                this.logsUsers = response.data.logs
            })
            .catch( () => {
                Swal.fire(
                    'Logs',
                    'Ocorreu um erro ao carregar os logs',
                    'error'
                )   
            })
        },

        addInteraction(){
            this.totalInteractions = this.totalInteractions + parseInt(this.packageInteractions.replace('.', ''))
            this.packageInteractions = ''
        },

        removeInteraction(){
            if (this.packageInteractions > this.totalInteractions){
                Swal.fire(
                    'Pacote de Interação',
                    'O valor de remoção é acima do pacote disponível de interações',
                    'warning'
                )
            }else{
                this.totalInteractions = this.totalInteractions - parseInt(this.packageInteractions.replace('.',''))
                this.packageInteractions = ''
            }
        },

        save(){
            var data = {
                ativado: this.active,
                total_interacao: this.totalInteractions,
                alertar_porcentagem: this.percentage,
                alertar_emails: this.emails,
                intervalo_horas: this.intervalHours
            }

            if (this.totalInteractionsLog > this.totalInteractions){
                this.logs['acao'] = 'Removeu valor do pacote'
                this.logs['valor'] = this.totalInteractionsLog - this.totalInteractions
            }else{
                if (this.totalInteractions > this.totalInteractionsLog){
                    this.logs['acao'] = 'Adicionou valor pacote'
                    this.logs['valor'] = this.totalInteractions - this.totalInteractionsLog
                }else{
                    this.logs['acao'] = 'Não teve alteração no pacote'
                    this.logs['valor'] = 0
                }
            }

            this.logs['total'] = this.totalInteractions
            this.logs['id_usuario'] = this.idUser

            axiosApi.post(`/whatsapp-interaction/campaign/${this.phone}/alert/${this.id}`, data)
                .then( () =>{

                    axiosApi.post(`/whatsapp-interaction/campaign/${this.phone}/alert/logs/${this.id}`, this.logs)
                    .then( () =>{
                        Swal.fire(
                            'Alterado!',
                            'A campanha foi alterada com sucesso!',
                            'success'
                        ).then(() => {
                            this.setClearCache(true)
                            console.log('chamado')
                            EventBus.$emit('onLoadAlerts')
                            this.setPhoneDefault(this.phone)
                            router.push({name: 'listcampaigns'})
                        })
                    })
                    .catch( () => {
                        Swal.fire(
                            'Alterado!',
                            'A campanha foi alterada, mas ocorreu um erro ao gerar o log.',
                            'warning'
                        ).then(() => {
                            EventBus.$emit('onLoadAlerts')
                            this.setPhoneDefault(this.phone)
                            router.push({name: 'listcampaigns'})
                        })
                    })

                })
                .catch( (error) => {
                    if ('campo_erro' in error.response.data){
                        this.errors = error.response.data.campo_erro
                    }
                })
        }
    }
}