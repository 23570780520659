import {mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(['isAdmin', 'getUserID', 'getToken', 'isLoading']),
    },

    filters: {
        formatNumber(value) {
            var item = parseInt(value)
            return item.toLocaleString('pt-BR')
        }
    }
};
