import axiosApi from '@/axios'
import router from '@/router'
export default {

    data() {
        return {
            users:{}
        }
    },

    watch:{

    },

    computed:{
      
    },

    created() {
        this.load()
    },

    mounted() {
    
    },

    methods: {
        onEdit(id){
            router.push({name: 'edituser', params:{id: id}})
        },

        load(){
            axiosApi.get('/whatsapp-interacao/user', { clearCacheEntry: true })
                .then( (response) =>{
                    this.users = response.data.usuarios
            })
                .catch( () => {

            })
        }
   
    }
}