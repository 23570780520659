import { mapMutations, mapGetters } from 'vuex'
import axiosApi from '@/axios';
import router from '@/router'

export default {
    data(){
        return{
            email: '',
            senha: '',
            erros:{},
            erro: ''
        }
    },

    created(){
        if (this.isLogin){
            router.push('/dashboard')
        }
    },

    watch:{

    },

    computed:{
        ...mapGetters(['isLogin', 'isLoading'])
    },

    methods:{
        login(){
            this.erros = {}
            this.erro = ''
            var data = {'email': this.email, 'senha': this.senha}
            axiosApi.post('/whatsapp-interacao/login', data)
                .then( (response) =>{
                    this.setLogin(response.data.token)
                    router.push('/dashboard')
            })
                .catch( (error) => {
                    if ('campo_erro' in error.response.data){
                        this.erros = error.response.data.campo_erro
                    }else{
                        if ('erro' in error.response.data){
                            this.erro = error.response.data.erro
                        }
                    }
            })
        },
        ...mapMutations(['setLogin'])
    }
}