<script>
import { Bar } from 'vue-chartjs'
import EventBus from '@/event/event-bus'

export default {
    extends: Bar,

    props:{
        id: Number
    },

    data(){
        return {
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales:{
                    xAxes: [{
                        display: false
                    }]
                }
            },
        }
    },

    created(){

    },

    mounted() {
        EventBus.$on('onChangeBarChart', (id, data) => {
            if (this.id === id){
                setTimeout(() => {
                    this.renderChart(data, this.options)
                }, 250);
            }
        })
    }
};
</script>
