<template>
    <div class="row g-4">
        <div v-for="index in count" :key="index" class="col-6 col-md-4 col-xl-3 col-xxl-2 mb-1">
            <div class="app-card app-card-doc shadow-sm h-100">
                <div class="app-card-thumb-holder p-3">
                </div>
                <div class="app-card-body p-3 has-card-actions">
                    <h4 class="app-doc-title truncate mb-0">

                    </h4>
                    <div class="app-doc-meta">
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                    <!--//app-doc-meta-->
                </div>
                <!--//app-card-body-->
            </div>
            <!--//app-card-->
        </div>
    </div>
</template>

<script>
export default {
    props: {
        count:Number
    },

    computed: {

    },

    created(){
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style>
    .line {
        height: 12px;
        margin-bottom:6px;
        border-radius: 2px;
        background: rgba(130, 130, 130, 0.2);
        background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
        background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
        background-size: 800px 100px;
        animation: wave-lines 2s infinite ease-out;
    }

@keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
 @keyframes wave-squares {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}

</style>