<template>
    <div class="app-card app-card-stat shadow-sm h-100">
        <div class="app-card-body p-3 p-lg-4">
            <h4 class="stats-type mb-1">{{title}}</h4>
            <div v-if="!isLoaded">
                <div class="stats-figure">{{this.newValue}}</div>
            </div>
            <div v-else class="text-center">
                <img src="@/assets/img/loading-info.gif" alt="logo-promo" />
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '@/event/event-bus'
export default {
    data() {
        return {
            newValue: 0,
            isLoaded: true
        };
    },

    props: {
        id: Number,
        title: String,
        value: Number,
        isLoad: Boolean
    },

    watch:{
        value(value){
            this.newValue = value
        },

        isLoad(value){
            this.isLoaded = value
        }
    },

    computed: {

    },

    created() {

    },

    mounted() {
         EventBus.$on('onCompleteCard', (id, value) =>{
             if (this.id == id){
                 this.newValue = value
                 this.isLoaded = false
             }
         })
    },

    methods: {

    },
};
</script>