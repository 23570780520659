<template>
    <div class="item p-3">
        <div
            class="
                row
                gx-2
                justify-content-between
                align-items-center
            "
        >
            <ImageLogo :source="image" classDiv="col-auto" classImg="profile-img image-size"/>
            <!--//col-->
            <div class="col">
                <div class="info">
                    <div class="desc">
                        {{text}}
                    </div>
                    <div class="meta">
                        {{subText}}
                    </div>
                </div>
            </div>
            <!--//col-->
        </div>
        <!--//row-->
        <a
            class="link-mask"
            href="#" @click.prevent="linkTo()"
        ></a>
    </div>
</template>

<script>
import ImageLogo from '@/components/campaign/ImageLogo.vue'
import router from '@/router'
export default {

    components:{
        ImageLogo
    },

    props:{
        image: String,
        text: String,
        subText: String,
        phone: String,
        id: Number
    },

    data() {
        return {
        }
    },

    created() {

    },

    mounted() {
    
    },

    methods:{
        linkTo(){
            router.push({name: 'alertcampaigns', params:{'phone': this.phone, 'id': this.id}})
        }
    }
}
</script>

<style lang="scss">
    .image-size{
        width: 100%;
        max-width: 50px;
}
</style>