import axiosApi from '@/axios'
import CampaignItem from '@/components/campaign/CampaignItem.vue'
import {mapGetters, mapMutations } from 'vuex'
import EventBus from '@/event/event-bus'
import Swal from 'sweetalert2'
import _ from 'lodash'
import router from '@/router'
import Skeleton from '@/components/skeleton/Skeleton.vue'

export default {
    components:{
        CampaignItem, Skeleton
    },

    data(){
        return{
            modal: null,
            search: '',
            phones:[],
            selectPhone: '',
            campaigns:[],
            querySearch: ''
        }
    },

    created(){
        if ((this.$route.query.phone) && (this.$route.query.search)){
            this.setSearchCompaign(true)
            this.setPhoneDefault(this.$route.query.phone)
        } 
    },

    computed:{
        ...mapGetters(['isLoading', 'phoneDefault', 'isSearchCompaign'])
    },

    mounted(){
        axiosApi.get('/whatsapp-interaction/campaign/phones')
            .then( (response) =>{
                this.phones = response.data.telefones
        }).then(() =>{
            if (this.phones.length > 0){
                if (this.isSearchCompaign){
                    if ((this.$route.query.phone) && (this.$route.query.search)){
                        this.querySearch = this.$route.query.search
                        this.selectPhone = this.$route.query.phone
                    }else{
                        this.selectPhone = this.phoneDefault
                    }                
                }else{
                    if (this.phoneDefault){
                        this.selectPhone = this.phoneDefault
                        this.setPhoneDefault('')
                    }else{
                        this.selectPhone = this.phones[0]
                    }
                }
                this.loadCampaign()
            }
        })

        EventBus.$on('onCampaignEdit', (phone, id) => {
            router.push({ name:'editcampaigns', params: { phone: phone, id: id } })
        })

        EventBus.$on('onCampaignInteraction', (phone, id) => {
            router.push({name: 'alertcampaigns', params: { phone: phone, id: id }})
        })

        EventBus.$on('onCampaignDelete', (phone, id) => {
            Swal.fire({
                title: 'Deletar campanha?',
                text: 'Tem certeza que deseja deletar esta campanha?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Sim, eu quero!',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    axiosApi.delete(`whatsapp-interaction/campaign/${phone}/delete/${id}`)
                        .then( () =>{
                            var index = _.findIndex(this.campaigns, function(campaign) { return campaign.id == id})
                            this.campaigns.splice(index, 1)
                            this.setClearCache(true)
                            Swal.fire(
                                'Deletado!',
                                'A campanha foi deletada com sucesso!',
                                'success'
                            )
                    })  .catch( (error) => {
                        if (error.response.status != 401){
                            Swal.fire(
                                'Erro',
                                'Aconteceu um erro ao deletar. Tente novamente',
                                'error'
                            )
                        }
                    })
                }
            })
        })
    },

    watch:{

    },

    methods:{
        ...mapMutations(['setPhoneDefault', 'setSearchCompaign', 'setClearCache']),
        newCampaign(){
            this.setPhoneDefault(this.selectPhone)
            router.push({name: 'newcampaigns'})
        },

        changePhone(){
            this.loadCampaign()
        },

        loadCampaign(){
            var url = null
            if (this.isSearchCompaign){
                url = `/whatsapp-interaction/campaign/${this.selectPhone}?search=${this.querySearch}`
            }else{
                url = `/whatsapp-interaction/campaign/${this.selectPhone}`
                this.setPhoneDefault(this.selectPhone)
                router.push({name: 'listcampaigns', query: {}})
            }

            axiosApi.get(url, { clearCacheEntry: true })
            .then( (response) =>{
                this.campaigns = response.data.campanha
                this.setSearchCompaign(false)
            })
        },

        searchCompaign(){
            this.setSearchCompaign(true)
            router.push({name: 'listcampaigns', query: {phone: this.selectPhone, search: this.search}})
        }
    }
}