<template>
    <div class="app-utilities col-auto">
        <div
            class="
                app-utility-item app-notifications-dropdown
                dropdown
            "
        >
            <a
                class="dropdown-toggle no-toggle-arrow"
                id="notifications-dropdown-toggle"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-expanded="false"
                title="Notifications"
            >
                <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
                <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-bell icon"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2z"
                    />
                    <path
                        fill-rule="evenodd"
                        d="M8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                    />
                </svg>
                <span v-if="notificationQtde > 0" class="icon-badge">{{notificationQtde}}</span>
            </a>
            <!--//dropdown-toggle-->

            <div
                class="dropdown-menu p-0"
                aria-labelledby="notifications-dropdown-toggle"
            >
                <div class="dropdown-menu-header p-3">
                    <h5 class="dropdown-menu-title mb-0">
                        Notificação
                    </h5>
                </div>
                <!--//dropdown-menu-title-->
                <div v-if="items.length > 0" class="dropdown-menu-content">
                    <notification-item v-for="item in items" :key="item.campanha.id" 
                                       :image="item.campanha.url_webhook_post"
                                       :text="getDescription(item)"
                                       :subText="item.campanha.porcentagem + '%'"
                                       :phone="item.campanha.whatsapp_id"
                                       :id="item.campanha.id"/>
                </div>
                <!--//dropdown-menu-content-->

                <div v-else
                    class="
                        dropdown-menu-footer
                        p-2
                        text-center
                    "
                >
                Não há notificações
                </div>
            </div>
            <!--//dropdown-menu-->
        </div>

        <div
            class="
                app-utility-item app-user-dropdown
                dropdown
            "
        >
            <a
                class="dropdown-toggle"
                id="user-dropdown-toggle"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-expanded="false"
                ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="currentColor"
                    class="bi bi-person-circle"
                    viewBox="0 0 16 16"
                >
                    <path
                        d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                    />
                    <path
                        fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    /></svg
            ></a>
            <ul
                class="dropdown-menu"
                aria-labelledby="user-dropdown-toggle"
            >
                <li>
                    <router-link
                        class="dropdown-item"
                        :to="{ name: 'account' }"
                        >Minha Conta</router-link
                    >
                </li>
                <li>
                    <hr class="dropdown-divider" />
                </li>
                <li>
                    <a class="dropdown-item" href="/logout"
                        >Sair</a
                    >
                </li>
            </ul>
        </div>
        <!--//app-user-dropdown-->
    </div>
</template>
<script>
import NotificationItem from '@/components/notification/NotificationItem.vue'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import _ from 'lodash'
import EventBus from '@/event/event-bus'

Vue.use(VueAxios, axios)

const axiosNotification = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL_API,
    headers: {'Authorization': `RPM 18207f79-843a-56fd-b7e3-6ebd66ee7b75`}
})

export default {
    components:{
        NotificationItem
    },

    data() {
        return {
            notificationQtde: 0,
            items:[]
        }
    },

    created() {
        this.loadAlerts()
    },

    mounted() {
        EventBus.$on('onLoadAlerts', () =>{
            this.loadAlerts()
        })
    },
    
    
    methods:{
        linkUrl(whatsAppID, id){
            var baseUrl = window.location.origin
            return `${baseUrl}/campaigns/alert/${whatsAppID}/${id}`
        },

        getDescription(item){
            var total = item.alerta.total_interacao
            total = parseInt(total)
            return `A ${item.campanha.nome_promocao} atingiu o limite de ${item.alerta.alertar_porcentagem}% do pacote de interações de ${total.toLocaleString('pt-BR')}`
        },

        loadAlerts(){
            axiosNotification.get('/whatsapp/alerts').then((response) =>{
                var notifications = _.filter(response.data.ativos, function(nt) { return nt.alerta.alertar; });
                this.items = notifications
                this.notificationQtde = this.items.length
            })
        }
    }
}
</script>