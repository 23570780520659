import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/home/Home.vue'
import Login from '@/views/login/Login.vue'
import NotFound from '@/views/404/NotFound.vue'
import Campaigns from '@/views/campaigns/Campaigns.vue'
import NewEditCampaigns from '@/views/campaigns/NewEditCampaigns.vue'
import AlertCampaigns from '@/views/campaigns/AlertCampaigns.vue'
import ListCampaigns from '@/views/campaigns/ListCampaigns.vue'
import User from '@/views/user/User.vue'
import NewEditUser from '@/views/user/NewEditUser.vue'
import ListUser from '@/views/user/ListUser.vue'

import Footer from '@/template/footer/Footer.vue'
import Header from '@/template/header/Header.vue'

import store from '@/store'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'Zap Manager'

const routes = [

    {
        path: '/',
        redirect: {
            name: 'dashboard'
        }
    },

    {
        path: '/login',
        name: 'login',
        component: Login,
        meta:{
            title: 'Login'
        }
    },

    {
        path: '/logout',
        name: 'logout',
        component: {
            beforeRouteEnter(to, from, next) {
                store.commit('logout')
                next('/login')
            }
        }
    },

    {
        path: '/dashboard',
        name: 'dashboard',
        components: {
            default: Home,
            header: Header,
            footer: Footer
        },
        meta: {
            title: 'Dashboard',
            requireLogin: true
        }
    },

    {
        path: '/campaigns',
        name: 'campaigns',
        components: {
            default: Campaigns,
            header: Header,
            footer: Footer
        },
        meta: {
            requireLogin: true
        },
            children: 
            [
                {
                    path: '/campaigns',
                    name: 'listcampaigns',
                    component: ListCampaigns,
                    meta: {
                        title: 'Campaigns',
                        requireLogin: true
                    }
                }, 
                {
                    path: 'new',
                    name: 'newcampaigns',
                    component: NewEditCampaigns,
                    meta: {
                        title: 'New Campaign',
                        requireLogin: true
                    }
                },
                {
                    path: 'edit/:phone/:id',
                    name: 'editcampaigns',
                    component: NewEditCampaigns,
                    props: true,
                    meta: {
                        title: 'Edit Campaign',
                        requireLogin: true,
                        isAdmin: true
                    }
                },
                {
                    path: 'alert/:phone/:id',
                    name: 'alertcampaigns',
                    component: AlertCampaigns,
                    props: true,
                    meta: {
                        title: 'Alert Campaign',
                        requireLogin: true
                    }
                }
            ]
    },
    {
        path: '/users',
        name: 'user',
        components: {
            default: User,
            header: Header,
            footer: Footer
        },
        meta: {
            requireLogin: true
        },
            children: 
            [
                {
                    path: '/users',
                    name: 'listuser',
                    component: ListUser,
                    meta: {
                        title: 'User',
                        requireLogin: true,
                        isAdmin: true
                    }
                }, 
                {
                    path: 'new',
                    name: 'newuser',
                    component: NewEditUser,
                    meta: {
                        title: 'New user',
                        requireLogin: true,
                        isAdmin: true
                    }
                },
                {
                    path: 'edit/:id',
                    name: 'edituser',
                    component: NewEditUser,
                    props: true,
                    meta: {
                        title: 'Edit user',
                        requireLogin: true,
                        isAdmin: true
                    }
                }
            ]
    },

    {
        path: '/account',
        name: 'account',
        components: {
            default: NewEditUser,
            header: Header,
            footer: Footer
        },
        props: true,
        meta: {
            title: 'Account',
            requireLogin: true,
        }
    },

    {
        path: '*',
        name: 'notfound',
        component: NotFound,
        meta: {
            title: 'Not Found'
        }
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

const originalPush = router.push
router.push = function push(location, onResolve, onReject)
{
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
 
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err
        }
   
        return Promise.reject(err)
    })
}

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title  + ' | ' + DEFAULT_TITLE || DEFAULT_TITLE;
    })
})

router.beforeEach((to, from, next) => {
    if (to.meta.requireLogin) {
        if (!store.getters.isLogin) {
            next('/login')
        } else {
            if (to.meta.isAdmin){
                if (store.getters.isAdmin){
                    next()
                }else{
                    next('/dashboard')
                }
            }else{
                next()
            }
        }
    }else{
        next()
    }
})

export default router